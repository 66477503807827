<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="General" name="general"></el-tab-pane>
      </el-tabs>
      <keep-alive>
        <alter-form :watchImageVisible="false" :modifyData="modifyData" @formSubmit="formSubmit" :formData="formData"></alter-form>
      </keep-alive>
    </el-card>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
// import Utils from '@/utils/Utils.js'
import apis from '@/services/index'

export default {
  name: 'tariffGroupsAdd',
  components: {
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      name: 'generalVue',
      activeName: 'general'
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const tariffs = await apis.TariffsBillings.getTariffsSimpleList()
      this.formData[1].options = tariffs.data.tariffs
      // const evse = await apis.AssetApi.getEvsesSimpleList()
      // this.formData[1].options = evse.data.evses
      if (this.$route.params.id) {
        const { data } = await apis.TariffsBillings.getTariffGroupsPreview(this.$route.params.id)
        this.modifyData = data.tariff_group
      }
      this.$store.state.app.loading.close()
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        tariff_group: obj
      }
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = apis.TariffsBillings.getTariffGroupsEdit
        arr.push(this.$route.params.id)
      } else {
        api = apis.TariffsBillings.getTariffGroupsAdd
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            onClose: () => { this.$router.go(-1) }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleClick () {
    }
  }
}
</script>

<style lang="scss" scope>
</style>
