import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 16,
    prop: 'name',
    name: 'input',
    label: i18n.t('name'),
    placeholder: i18n.t('name'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'tariff_ids',
    name: 'select',
    label: i18n.t('Tariffs'),
    placeholder: i18n.t('Tariffs'),
    Object: {
      value: 'id',
      filterable: true,
      multiple: true
    }
  }]
  // {
  //   span: 16,
  //   prop: 'evse_ids',
  //   name: 'select',
  //   label: i18n.t('EVSEs'),
  //   placeholder: i18n.t('placeholder.defaultInput'),
  //   Object: {
  //     value: 'id',
  //     filterable: true,
  //     multiple: true
  //   }
  // }
}
